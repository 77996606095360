import React from 'react';
import FooterTop from '../components/FooterTop';
import FooterBottom from '../components/FooterBottom';

export const Footer = () => {
  return (
    <div className='flex flex-col'>
        <FooterTop />
        <FooterBottom />
    </div>
  )
}

export default Footer;