import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailInput from './EmailInput';

const NewsLetter = () => {
    const navigate = useNavigate();

    return (
        <div className='px-5 lg:px-[120px] py-14 lg:py-24'>
            <div className='flex flex-col items-center justify-center gap-4 bg-[#F9FAFB] py-4 lg:py-8 px-6 md:px-16 rounded-[32px]'>
                <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center'>
                    Subscribe to our newsletter
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center'>
                    Join our Networth Community and never miss out on exciting opportunities.
                </div>
                <div className='flex flex-col gap-4'>
                    <EmailInput text="Subscribe"  id='newsletter-share' />
                    <div className='text-[#6B7280] font-normal text-[14px] leading-5 py-4 md:py-2'>
                        We care about your data. Read our <span className='border-[#6B7280] border-b-2 hover:cursor-pointer' onClick={() => navigate('/privacy-policy')}>privacy policy</span>.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter