import React from "react";
import BarChart from "../assets/images/Icons/BarChart.svg";
import CollabFinance from "../assets/images/Icons/CollabFinance.svg";
import FinanceSolution from "../assets/images/Icons/FinanceSolution.svg";
import HolisticPlanning from "../assets/images/Icons/HolisticPlanning.svg";
import Mobiles from "../assets/images/Mobiles.png";

const Different = () => {
  return (
    <div className="px-5 md:px-14 xl:px-[120px] py-4 lg:py-8 xl:py-14 flex flex-col xl:flex-row gap-8 lg:gap-12 xl:gap-16">
      <div className="w-full xl:w-[50%] flex flex-col gap-4 md:gap-8 justify-center">
        <div className="flex flex-col gap-2">
          <div className="text-[#0A0A0A] font-semibold text-[30px] md:text-[40px] leading-[52px]">
            <h1>How Are We Different?</h1>
          </div>
          <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-[90%]">
            <p>
              Our solutions grow seamlessly with your finances, adapting to your
              expanding needs.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-2">
            <div className="bg-[#80C1FF] h-10 w-10 rounded-[10px] flex items-center justify-center">
              <img src={CollabFinance} alt="" />
            </div>
            <div className="text-[#0A0A0A] font-bold text-[16px] md:text-[18px] leading-6 md:leading-7">
              <h3>Collaborative Family Finances</h3>
            </div>
            <div className="text-[#737373] font-normal text-[13px] md:text-[14px] leading-4 md:leading-6">
              <p>
                Strengthen your financial partnership by involving your spouse
                or family in decision-making and utilizing shared financial
                tools.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="bg-[#80C1FF] h-10 w-10 rounded-[10px] flex items-center justify-center">
              <img src={HolisticPlanning} alt="" />
            </div>
            <div className="text-[#0A0A0A] font-bold text-[16px] md:text-[18px] leading-6 md:leading-7">
              <h3>Holistic Financial Planning</h3>
            </div>
            <div className="text-[#737373] font-normal text-[13px] md:text-[14px] leading-4 md:leading-6">
              <p>
                Our personalized guidance helps you manage your entire financial
                life, from saving and investing to retirement planning and more.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="bg-[#80C1FF] h-10 w-10 rounded-[10px] flex items-center justify-center">
              <img src={BarChart} alt="" />
            </div>
            <div className="text-[#0A0A0A] font-bold text-[16px] md:text-[18px] leading-6 md:leading-7">
              <h3>Goal-Driven Financial Management</h3>
            </div>
            <div className="text-[#737373] font-normal text-[13px] md:text-[14px] leading-4 md:leading-6">
              <p>
                Our strategic method aligns your financial goals with clear,
                measurable objectives to effectively manage your money.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="bg-[#80C1FF] h-10 w-10 rounded-[10px] flex items-center justify-center">
              <img src={FinanceSolution} alt="" />
            </div>
            <div className="text-[#0A0A0A] font-bold text-[16px] md:text-[18px] leading-6 md:leading-7">
              <h3>Financial Flow Solutions</h3>
            </div>
            <div className="text-[#737373] font-normal text-[13px] md:text-[14px] leading-4 md:leading-6">
              <p>
                Our optimized Cash Flow and Lending Solutions will help you
                maximize income, minimize costs, and secure optimal financing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-[50%] flex items-center justify-center">
        <img src={Mobiles} alt="" />
      </div>
    </div>
  );
};

export default Different;
