import React from 'react'

const SecurityPractices = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Reasonable Security Practices for Protection of Personal Information
            </div>
            <p>
                We store and process your personal information on our secured systems. We protect your data using physical, technical, and administrative measures, such as encryption, access controls, and security protocols, to reduce the risks of unauthorized access, misuse, or alteration.
            </p>
        </div>
    )
}

export default SecurityPractices