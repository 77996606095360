import React from "react";
import KhyatiAvatar from "../assets/images/Avatars/KhyatiAvatar.png";
import ImgOne from "../assets/images/BlogPageTwo/ImgOne.png";
import ImgTwo from "../assets/images/BlogPageTwo/ImgTwo.png";

const BlogPageTwoInfo = () => {
  return (
    <div className="px-5 xl:px-[120px]">
      <div className="flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4">
        <div className="text-blue-primary text-center font-semibold">
          Fintech
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center">
          Diversify Like Ambani: Build a Stronger Financial Future
        </div>
        <div className="text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center">
          Stay ahead of the curve with our latest blog releases
        </div>
        <div className="flex gap-4">
          <div className="flex items-center justify-center">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-[#111827] font-semibold text-[16px] leading-6">
              Khyati Gandhi
            </div>
            <div className="text-[#4B5563] font-normal text-[14px] leading-5">
              Product Designer
            </div>
          </div>
        </div>
        <div className="xl:w-[1024px] xl:h-[550px]">
          <img
            src={ImgOne}
            alt=""
            className="w-full h-full object-cover rounded-[8px]"
          />
        </div>
      </div>
      <div className="flex flex-col  gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52">
        <div className="flex flex-col gap-5">
          <p>
            Mukesh Ambani, the titan of Indian business, is a name synonymous
            with success. His Reliance Industries, once a petrochemical giant,
            has morphed into a behemoth spanning telecom, retail, and digital
            services. This transformation is a masterclass in financial
            strategy, and one of the most critical lessons we can extract is the
            power of diversification.
          </p>
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            What is Diversification?
          </div>
          <p>
            Diversification essentially means spreading your investments across
            various assets to reduce risk. Just as Ambani didn't put all his
            eggs in the petrochemical basket, you shouldn't put all your
            financial hopes in one asset class.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="xl:w-[768px] xl:h-[486px]">
            <img
              src={ImgTwo}
              alt=""
              className="w-full h-full object-cover rounded-[8px]"
            />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            How Can You Apply This?
          </div>
          <div className="px-6">
            <ol className="list-decimal xl:ml-6">
              <li className="text-[#111827] font-bold">
                <div className="text-[#111827] font-bold leading-6 md:leading-8">
                  Invest in Multiple Asset Classes:
                </div>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Stocks:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Ownership in companies can offer significant returns but
                      is also volatile.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Bonds:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      These debt instruments provide steady income and are
                      generally less risky.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Real Estate:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Property can be a stable investment, offering rental
                      income and potential appreciation.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Other Alternatives:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Consider gold, commodities, or even cryptocurrencies (with
                      caution) for further diversification.
                    </span>
                  </li>
                </ul>
              </li>
              <li className="text-[#111827] font-bold">
                <div className="text-[#111827] font-bold leading-6 md:leading-8">
                  Invest in Multiple Asset Classes:
                </div>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Stocks:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Ownership in companies can offer significant returns but
                      is also volatile.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Bonds:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      These debt instruments provide steady income and are
                      generally less risky.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Real Estate:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Property can be a stable investment, offering rental
                      income and potential appreciation.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Other Alternatives:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Consider gold, commodities, or even cryptocurrencies (with
                      caution) for further diversification.
                    </span>
                  </li>
                </ul>
              </li>
              <li className="text-[#111827] font-bold">
                <div className="text-[#111827] font-bold leading-6 md:leading-8">
                  Diversify Within Asset Classes:
                </div>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Don't put all your money in one stock or sector:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Spread your investments across different companies and
                      industries.
                    </span>
                  </li>
                </ul>
                <ul className="list-circle ml-4 xl:ml-6">
                  <li className="text-[#111827]">
                    <span className="text-[#111827] font-semibold leading-8">
                      Geographic Diversification:{" "}
                    </span>
                    <span className="text-[#4B5563] font-normal">
                      Invest in companies from different regions to reduce
                      country-specific risks.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Why is Diversification Important?
          </div>
          <div className="px-6">
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#111827] font-semibold leading-8">
                  Risk Reduction:{" "}
                </span>
                <span className="text-[#4B5563]">
                  No asset class is immune to downturns. Diversification helps
                  cushion the impact.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#111827] font-semibold leading-8">
                  Enhanced Returns:{" "}
                </span>
                <span className="text-[#4B5563]">
                  Different asset classes perform differently in various
                  economic conditions. A diversified portfolio can potentially
                  offer better overall returns.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#111827] font-semibold leading-8">
                  Peace of Mind:{" "}
                </span>
                <span className="text-[#4B5563]">
                  Knowing your money is spread across various investments can
                  provide financial security.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Conclusion
          </div>
          <p>
            Diversification is a long-term strategy. It's not about timing the
            market but about time in the market. Consult a financial advisor to
            create a personalized diversification plan that aligns with your
            financial goals and risk tolerance.
          </p>
          <p>
            By following these principles, you can build a robust financial
            portfolio that can weather economic storms and help you achieve your
            long-term financial aspirations, just like Mukesh Ambani.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPageTwoInfo;
