import React from 'react'

const SharingInfo = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Sharing of Personal Information
            </div>
            <p>
                Networth Tracker will not sell or rent your personal information to anyone. However, we may share your information with:
            </p>
            <div>
                <ul className='list-disc ml-4 xl:ml-6'>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Group Companies: </span><span className='text-[#4B5563] font-normal'>For providing joint services, detecting fraud, and marketing communications..</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Financial Institutions and Partners: </span><span className='text-[#4B5563] font-normal'>To assist in providing Networth Tracker related products.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Law Enforcement or Legal Processes: </span><span className='text-[#4B5563] font-normal'>To comply with legal requirements or protect against harm.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Service Providers: </span><span className='text-[#4B5563] font-normal'>To support operations such as fraud prevention, KYC, marketing, and customer service.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Other Third Parties: </span><span className='text-[#4B5563] font-normal'>With your consent or direction.</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SharingInfo