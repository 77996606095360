import React, { useEffect } from "react";

const TryDemoModal = ({ setOpenDemoModal }) => {
  useEffect(() => {
    // Add open class after mounting to trigger animation
    const modal = document.querySelector(".demo-modal-content");
    setTimeout(() => {
      modal.classList.remove("scale-75", "opacity-0");
      modal.classList.add("scale-100", "opacity-100");
    }, 10); // small delay to ensure the transition triggers properly
  }, []);

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("demo-modal")) {
      setOpenDemoModal(false);
    }
  };

  return (
    <>
      <div
        className="demo-modal fixed inset-0 z-50 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center"
        onClick={handleOutsideClick}
      >
        <div className="demo-modal-content flex flex-col justify-center items-center w-11/12 h-5/6 bg-white rounded-2xl p-6 lg:px-12 lg:py-6 relative transform scale-75 opacity-0 transition-all duration-300 ease-in-out">
          <span
            className="absolute top-6 right-6 lg:top-1 lg:right-3 text-3xl cursor-pointer"
            onClick={() => setOpenDemoModal(false)}
          >
            &times;
          </span>

          <div className="disclaimer-container flex-grow w-full overflow-y-auto">
            <h1 className="text-2xl font-semibold text-black">Disclaimer:</h1>
            <div className="mt-6 text-gray-700">
              <div className="my-4">
                <p className="mt-2 mb-4 text-blue-primary font-medium">
                  This is a visual demonstration only. We{" "}
                  <strong>do not</strong> require any OTP, personal data, or
                  sensitive information in this demo. Feel free to leave all
                  fields empty.
                </p>
                <p className="my-2">
                  1. <strong>Data Privacy & Security:</strong> This demo does
                  not store or retain any information entered during the
                  session. All fields are for demonstration only, and no
                  personal or financial data is required. Security features
                  shown, such as login and verification, are illustrative only.
                </p>
                <p className="my-2">
                  2. <strong>Non-Functional System:</strong> The demo showcases
                  the user interface and flow only, with no connection to live
                  financial services or real transactions.
                </p>
                <p className="my-2">
                  3. <strong>Optimal Experience:</strong> For the best
                  experience, use a mobile device. A dedicated desktop view is
                  also in development.
                </p>
              </div>
            </div>

            <h2 className="text-xl font-semibold text-black mt-8">
              How to Install this Site as a Mobile App (Lite Version):
            </h2>

            <div className="mt-6 text-gray-700">
              <div className="my-4">
                <p className="my-2">
                  <strong>On Android devices:</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>Open the demo site in Chrome.</li>
                    <li>
                      Tap the three vertical dots in the top-right corner.
                    </li>
                    <li>Select "Add to Home screen."</li>
                    <li>Confirm by tapping "Add."</li>
                  </ul>
                </p>
              </div>
              <div className="my-4">
                <p className="my-2">
                  <strong>On iOS devices:</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>Open the demo site in Safari/Chrome.</li>
                    <li>
                      Tap the share icon (a box with an arrow pointing upwards).
                    </li>
                    <li>Scroll down and tap "Add to Home Screen."</li>
                    <li>Confirm by tapping "Add."</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div
            className="bg-blue-primary hover:bg-blue-secondary transition-all rounded-lg px-6 py-3 mt-6 mb-12 text-white cursor-pointer"
            onClick={() =>
              window.open("https://app.networthtracker.in", "_blank")
            }
          >
            Try Free Demo
          </div>
        </div>
      </div>
    </>
  );
};

export default TryDemoModal;
