import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import BlogPost from "../components/BlogPost";
import Different from "../components/Different";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import NetworthTracker from "../components/NetworthTracker";
import ProductsServices from "../components/ProductsServices";

const HomePage = () => {
  const productRef = useRef(null);
  const solutionsRef = useRef(null);
  const footerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (
      hash &&
      (footerRef.current || productRef.current || solutionsRef.current)
    ) {
      // setTimeout(() => {
      if (hash === "#CTO" && footerRef.current) {
        footerRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (hash === "#product" && productRef.current) {
        productRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (hash === "#solutions" && solutionsRef.current) {
        solutionsRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // }, 100);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>
          Networth Tracker - Empower Your Wealth: Track, Automate, Prosper!
        </title>
        <meta
          name="description"
          content="Tracking your finances is just the start. Unlock more with seamless tracking with our advanced AI-powered analytics, advisory and more."
        />
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="tracking-wide">
        <Header />
        <NavBar
          productRef={productRef}
          solutionsRef={solutionsRef}
          text="homePage"
        />
        <NetworthTracker />
        <ProductsServices />
        <Different />
        <FAQs />
        <BlogPost />
        <div ref={footerRef}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomePage;
