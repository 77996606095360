import React from 'react';
import NavBar from '../../components/NavBar';
import BlogPageSixInfo from '../../components/BlogPageSixInfo';
import BlogPost from '../../components/BlogPost';
import NewsLetter from '../../components/NewsLetter';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet-async';

const BlogPageSix = () => {
    return (
        <>
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <link rel="canonical" href="/blogs" />
            </Helmet>
            <div className='tracking-wide'>
                <NavBar />
                <BlogPageSixInfo />
                <BlogPost />
                <NewsLetter />
                <Footer />
            </div>
        </>
    )
}

export default BlogPageSix