import React from "react";

const OurBlog = () => {
  return (
    <div className="px-5 lg:px-[120px]">
      <div className="flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4">
        <div className="text-blue-primary font-semibold text-[16px] leading-6 text-center">
          Our Blog
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] text-center">
          Latest Blog Posts
        </div>
        <div className="text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center">
          Stay ahead of the curve with our latest blog releases
        </div>
      </div>
    </div>
  );
};

export default OurBlog;
