import React from 'react';
import NavBar from '../../components/NavBar';
import BlogPageTwoInfo from '../../components/BlogPageTwoInfo';
import BlogPost from '../../components/BlogPost';
import NewsLetter from '../../components/NewsLetter';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet-async';

const BlogPageTwo = () => {
  return (
    <>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <link rel="canonical" href="/blogs" />
      </Helmet>
      <div className='tracking-wide'>
        <NavBar />
        <BlogPageTwoInfo />
        <BlogPost />
        <NewsLetter />
        <Footer />
      </div>
    </>
  )
}

export default BlogPageTwo;