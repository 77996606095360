import React from "react";

const DashBoardLogo = (props) => {
  const toggleSidebar = () => {
    props.setIsSidebarOpen(!(props.isSidebarOpen));
  };
  return (
    <div className="w-full">
      <div className="bg-[#30628C] rounded-[4px] py-1 flex justify-between items-center">
        <div className="md:hidden z-50">
          <button
            onClick={toggleSidebar}
            className="bg-transparent text-white p-2 rounded-md focus:outline-none ml-2"
          >
            <i className="bi bi-list text-2xl"></i>
          </button>
        </div>
        <div className="text-[#E0E2E8] text-base leading-6 tracking-[0.1px] px-4">
          Dashboard
        </div>
      </div>
    </div>
  );
};

export default DashBoardLogo;
