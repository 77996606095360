import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpPage from "./OtpPage";

const EmailLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      alert("Invalid Email");
      return;
    }

    // Call  API

    setShowOtp(true);
  };

  return (
    <div className="h-screen flex justify-center items-center">
      {!showOtp ? (
        <div>
          <div className="flex flex-column items-center">
            <div className="w-[100%] flex justify-end px-4 py-6">
              <div
                className="border-2 border-blue-500 text-blue-500 text-center hover:cursor-pointer inline-block px-3 py-1 rounded-full"
                onClick={() => navigate("/dashboard")}
              >
                skip
              </div>
            </div>
            <div className="text-[45px] font-700 leading-[52px] p-6 mt-[10px]">
              Welcome!
            </div>
            <div className="text-gray-500 p-2 w-[260px] text-left">
              Enter your Email to get one time password
            </div>
            <div className="mb-4">
              <input
                type="text"
                value={email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="w-[260px] px-4 py-2 mb-4 border border-gray-750 rounded-[20px] focus:outline-none"
              />
            </div>
            <div className="relative">
              <div
                onClick={handleSubmit}
                className="w-[260px] py-2 border rounded-[10px] bg-gradient-to-r from-blue-500 to-violet-500 text-white cursor-pointer flex items-center group pl-20"
              >
                Get OTP
                <div className="h-full flex items-center ml-4 group-hover:ml-8 transition-all duration-500">
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <OtpPage length={4} text="Email" setShowOtp={setShowOtp} />
        </div>
      )}
    </div>
  );
};

export default EmailLogin;
