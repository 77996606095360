import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>
          Networth Tracker Products - Empower your financial growth with our
          tailored solutions
        </title>
        <meta
          name="description"
          content="Gain a clear picture of your finances, make informed decisions, and achieve your financial goals with financial clarity and confidence."
        />
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <link rel="canonical" href="/product" />
      </Helmet>
      <div className="">
        <NavBar />
        <div className="container mx-auto text-center py-16 min-h-[90vh] flex flex-col items-center justify-center">
          <h1 className="text-6xl font-bold text-blue-primary">404</h1>
          <h4 className="text-2xl mt-4 text-gray-800">Oops! Page Not Found</h4>
          <p className="mt-4 text-lg text-gray-600">
            It looks like you’ve hit a roadblock on your financial journey. The
            page you're looking for doesn't exist, or it may have been moved.
          </p>
          <p className="mt-4 text-lg text-gray-600">
            Don’t worry! You can still track your financial growth, manage your
            investments, and gain insights into your net worth.
          </p>
          <a
            href="/"
            className="mt-6 inline-block px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Back to Home
          </a>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NotFound;
