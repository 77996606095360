import React from 'react'

const Disclaimers = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Disclaimers
            </div>
            <p>
                Our Platform may contain links to third party sites that have their own privacy practices. Networth Tracker is not responsible for the privacy policies or content of such sites.
            </p>
            <p>
                For any grievances, complaints, or questions regarding this Privacy Policy, please contact our Grievance Officer at support@networthtracker.in.
            </p>
        </div>
    )
}

export default Disclaimers