import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const bankColorMap = {
    HDFC: ['#0033A0', '#FF0000'],
    Axis: ['#A52A2A'],
    ICICI: ['#FF6600'],
    Kotak: ['#1F1F7A'],
    IndusInd: ['#800020'],
    Yes: ['#005BAC', '#FF0000'],
    SBI: ['#154273'],
    'Bank of Baroda': ['#E65100'],
    'Union Bank': ['#005F9E', '#FF4040'],
    PNB: ['#8B0000'],
    'Indian Overseas Bank': ['#0047AB'],
    BOI: ['#FF9933', '#1E90FF'],
    IDFC: ['#800000'],
};

const defaultColors = [
    "#FF5733", "#FF8D1A", "#FFC300", "#FF6F61", "#DA70D6",
    "#FF33D4", "#00BFFF", "#2E8B57", "#32CD32",
    "#7FFF00", "#FFD700", "#FF4500", "#FF1493", "#FF6347",
    "#40E0D0", "#7B68EE", "#6A5ACD", "#8A2BE2", "#FFB6C1",
    "#20B2AA", "#ADFF2F", "#F08080", "#FFDAB9", "#FFDEAD",
    "#FFE4E1", "#BA55D3", "#FF69B4", "#FA8072"
];

const getColorOrGradient = (bankName, index) => {
    const colors = bankColorMap[bankName];
    if (colors && colors.length > 1) {
        return `url(#gradient-${index})`;
    }
    return colors ? colors[0] : defaultColors[index % defaultColors.length];
};

const PieCharts = ({ chartData }) => {
    return (
        <div className='w-[100%] h-[400px] flex items-center'>
            <div className='w-[70%] h-[100%]'>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <defs>
                            {chartData.map((entry, index) => {
                                const colors = bankColorMap[entry.name];
                                if (colors && colors.length > 1) {
                                    return (
                                        <linearGradient id={`gradient-${index}`} key={`gradient-${index}`} x1="0%" y1="0%" x2="100%" y2="100%">
                                            {colors.map((color, i) => (
                                                <stop offset={`${(i / (colors.length - 1)) * 100}%`} style={{ stopColor: color }} key={i} />
                                            ))}
                                        </linearGradient>
                                    );
                                }
                                return null;
                            })}
                        </defs>
                        <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={150}
                            fill="#CD5C5C"
                            dataKey="value"
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getColorOrGradient(entry.name, index)} />
                            ))}
                        </Pie>
                        <Tooltip
                            content={({ payload }) => {
                                if (payload && payload.length) {
                                    const { name } = payload[0];
                                    return (
                                        <div className="custom-tooltip">
                                            <p>{name}</p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className="legend-container" style={{ width: '30%', textAlign: 'left', paddingLeft: '10px' }}>
                {chartData.map((entry, index) => {
                    const colorOrGradient = getColorOrGradient(entry.name, index);
                    const gradientId = colorOrGradient.startsWith('url(#') ? `legend-gradient-${index}` : null;

                    return (
                        <div key={`legend-${index}`} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            {gradientId ? (
                                <svg width="12" height="12">
                                    <defs>
                                        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
                                            {bankColorMap[entry.name].map((color, i) => (
                                                <stop offset={`${(i / (bankColorMap[entry.name].length - 1)) * 100}%`} style={{ stopColor: color }} key={i} />
                                            ))}
                                        </linearGradient>
                                    </defs>
                                    <circle cx="6" cy="6" r="6" fill={`url(#${gradientId})`} />
                                </svg>
                            ) : (
                                <span style={{
                                    backgroundColor: colorOrGradient,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    width: '12px',
                                    height: '12px',
                                    marginRight: '5px'
                                }}></span>
                            )}
                            <span>{entry.name} (Rs {entry.originalValue.toFixed(2)})</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PieCharts;
