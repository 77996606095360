import React from "react";
import DownKeyIcon from "../assets/images/DownKey.png";
import CalendarIcon from "../assets/images/Calendar.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
} from "recharts";

const CustomLineChart = () => {
  const data = [
    { name: "Jan", expense: 15000 },
    { name: "Feb", expense: 60000 },
    { name: "Mar", expense: 40000 },
    { name: "Apr", expense: 30000 },
    { name: "May", expense: 80000 },
    { name: "Jun", expense: 78000 },
  ];

  const yAxisFormatter = (tick) => `${tick / 1000}k`;

  return (
    <div style={{ width: "100%" }}>
      <div className="px-2 flex justify-between items-center">
        <div className="font-semibold text-[#15223C] text-[22px]">
          Money Flow
        </div>
        <div className="flex gap-2 text-sm">
          <div className="border rounded-sm text-gray-400 px-2 py-1">
            Money flow
          </div>
          <div className="border flex justify-between gap-2 rounded-sm text-gray-400 px-2 py-1">
            <div>
              <img src={CalendarIcon} alt='' />
            </div>
            <div>6 Month</div>
            <div>
              <img src={DownKeyIcon} alt='' />
            </div>
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={true}
            horizontal={false}
            stroke="#E0E0E0"
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tickMargin={10}
          />
          <YAxis
            domain={[0, 100000]}
            tickFormatter={yAxisFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            width={60}
          />
          <Area
            type="monotone"
            dataKey="expense"
            stroke="#0079C1"
            fill="#ADD8E6"
          />
          <Line
            type="monotone"
            dataKey="expense"
            stroke="#0079C1"
            strokeWidth={4}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
