import React from 'react'

const Consent = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Express Consent
            </div>
            <p>
                By providing your details/documents over the Platform, including personal information as described above, you expressly consent to Networth Tracker (including its marketing channels and business partners) contacting you via SMS, call, and/or email in relation to Networth Tracker Services, including for promotional purposes.
            </p>
            <p>
                You also authorize us to share your personal information (only to the extent required) with third parties to provide you with Networth Tracker Services, such as your mobile number for OTP delivery and financial information retrieval.
            </p>
        </div>
    )
}

export default Consent