import React from 'react';
import SocialMediaPosts from './SocialMediaPosts.js';
import Highlights from './Highlights.js';
import Trending from './Trending.js';
import SocialMediaHandles from './SocialMediaHandles.js';
import Popular from './Popular.js';

const BlogContent = () => {
  return (
    <div className='px-5 xl:px-[120px]'>
        <div className='flex flex-col gap-10 xl:gap-24'>
            <div className='flex flex-col xl:flex-row gap-16'>
                <div className='flex flex-col gap-8 w-full xl:w-[60%]'>
                    <Highlights />
                    <Trending />
                </div>
                <div className='flex flex-col gap-12 w-full xl:w-[40%]'>
                    <SocialMediaHandles />
                    <Popular />
                </div>
            </div>
            <div>
                <SocialMediaPosts />
            </div>
        </div>
    </div>
  )
}

export default BlogContent