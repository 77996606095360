import React from 'react'

const DataRetention = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Retention of Data
            </div>
            <p>
                We retain your information for as long as necessary to provide Networth Tracker Services. Post termination of your account, we may retain anonymized data for analysis and research purposes. Certain information may be retained as required by law or contractual obligations.
            </p>
        </div>
    )
}

export default DataRetention