import React from 'react'

const Purpose = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Purpose of Collecting Data
            </div>
            <p>
                Our primary purpose in collecting personal information is to provide you with a secure, efficient, and customized experience. We may use your personal information to:
            </p>
            <div>
                <ul className='list-disc ml-4 xl:ml-6'>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-normal'>Provide Networth Tracker Services and support; process transactions; verify your identity.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-normal'>Resolve disputes; collect fees; detect, prevent, and/or remediate fraud or illegal activities.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-normal'>Improve Networth Tracker Services by customizing user experiences; measure performance.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-normal'>Manage our information technology infrastructure; ensure compliance with applicable laws.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-normal'>Provide targeted marketing and advertising based on your preferences; perform solvency checks.</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Purpose