import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../components/icons/LeftArrow";

const OtpPage = (props) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(props.length).fill(""));
  const [attempts, setAttempts] = useState(0);
  const [timer, setTimer] = useState(20);
  const [resendActive, setResendActive] = useState(false);
  const inputRefs = useRef([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }

    startTimer();

    return () => clearInterval(intervalRef.current);
  }, []);

  const startTimer = () => {
    clearInterval(intervalRef.current);

    setTimer(20);
    setResendActive(false);
    intervalRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          setResendActive(true);
          clearInterval(intervalRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const formatTime = (seconds) => {
    return `00:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    if (value && index < props.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
    else if (e.key === "Enter") {
      if (props.text === 'Email') {
        handleEmailVerify(e);
      } else {
        handlePhoneVerify(e);
      }
    }
  };

  const handlePhoneVerify = (e) => {
    e.preventDefault();
    const isOtpCorrect = true;

    if (isOtpCorrect) {
      navigate("/email-login");
    } else {
      setOtp(new Array(props.length).fill(""));
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
      setAttempts((prev) => prev + 1);
      if (attempts + 1 >= 3) {
        const blockUntil = Date.now() + 2 * 60 * 60 * 1000;
        localStorage.setItem(`block_${props.mobNum}`, blockUntil);
        alert("Your number is blocked for 2 hours.");
        props.setMobNum("");
        props.setShowOtp(false);
      } else {
        alert("Incorrect OTP. Please try again.");
      }
    }
  };

  const handleEmailVerify = (e) => {
    e.preventDefault();
    const isOtpCorrect = true;

    if (isOtpCorrect) {
      navigate("/dashboard");
    } else {
      setOtp(new Array(props.length).fill(""));
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
      alert("Incorrect OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    startTimer();
    alert("OTP has been resent.");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-start w-full my-2">
        <div className="hover:cursor-pointer" onClick={() => props.setShowOtp(false)}>
          <LeftArrow fill='gray' height="30px" />
        </div>
      </div>
      <div className="text-[30px] font-700 text-center">
        <div>Enter OTP sent to your</div>
        <div>{props.text}</div>
      </div>
      <div className="flex">
        {otp.map((value, index) => {
          return (
            <input
              key={index}
              type="text"
              ref={(input) => (inputRefs.current[index] = input)}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="px-2 py-2 border border-gray-750 w-[50px] h-[50px] m-[5px] text-center text-[1.2rem] rounded-[5px] focus:outline-none mb-10"
            />
          );
        })}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div
          onClick={props.text === 'Email' ? handleEmailVerify : handlePhoneVerify}
          className="w-[280px] py-2 border rounded-[10px] bg-gradient-to-r from-blue-500 to-violet-500 text-white cursor-pointer flex items-center group pl-20"
        >
          Verify OTP
          <div className="h-full flex items-center ml-4 group-hover:ml-8 transition-all duration-500">
            <i className="bi bi-arrow-right"></i>
          </div>
        </div>
        <div className="text-gray-500 mt-2">
          {formatTime(timer)}
        </div>
        <div
          onClick={resendActive ? handleResendOtp : null}
          className={`mt-2 ${resendActive ? 'text-blue-500 cursor-pointer' : 'text-gray-400 cursor-default'}`}
        >
          Resend OTP
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
