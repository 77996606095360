import React from 'react';
import LinkedinPost from '../assets/images/LinkedinPost.png';
import InstagramPost from '../assets/images/InstagramPost.png';
import FacebookPost from '../assets/images/FacebookPost.png';
import TwitterPost from '../assets/images/TwitterPost.png';

const SocialMediaPosts = () => {
    return (
        <div className='flex flex-col gap-8'>
            <div className='flex justify-between'>
                <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                    Popular post by social media
                </div>
                <div className='flex'>

                </div>
            </div>
            <div className='grid grid-rows-1 lg:grid-cols-2 xl:grid-cols-4 gap-6'>
                <a href='https://www.linkedin.com/feed/update/urn:li:activity:7230589221940436992/?actorCompanyId=101979858' target='_blank' rel="noreferrer" className='flex group'>
                    <div className='flex flex-col gap-4'>
                        <div>
                            <img src={LinkedinPost} alt='' className='w-full h-full object-cover rounded-[16px]' />
                        </div>
                        <div className='flex gap-3 text-[14px] leading-5'>
                            <div className='text-[#4B5563] font-normal'>
                                17 August 2024
                            </div>
                            <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                                LinkedIn
                            </div>
                        </div>
                        <div className='text-[#111827] font-semibold text-[20px] leading-7 group-hover:text-gray-600'>
                            Elon Musk's journey to the pinnacle of success.
                        </div>
                    </div>
                </a>
                <a href='https://twitter.com/NWTracker/status/1823695782110765257' target='_blank' rel="noreferrer" className='flex group'>
                    <div className='flex flex-col gap-4'>
                        <div>
                            <img src={TwitterPost} alt='' className='w-full h-full object-cover rounded-[16px]' />
                        </div>
                        <div className='flex gap-3 text-[14px] leading-5'>
                            <div className='text-[#4B5563] font-normal'>
                                14 August 2024
                            </div>
                            <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                                Twitter
                            </div>
                        </div>
                        <div className='text-[#111827] font-semibold text-[20px] leading-7 group-hover:text-gray-600'>
                            True wealth often comes from the art of patience.
                        </div>
                    </div>
                </a>
                <a href='https://www.instagram.com/p/C_BSF1vvhHh/?img_index=1' target='_blank' rel="noreferrer" className='flex group'>
                    <div className='flex flex-col gap-4'>
                        <div className=''>
                            <img src={InstagramPost} alt='' className='w-full h-full object-cover rounded-[16px]' />
                        </div>
                        <div className='flex gap-3 text-[14px] leading-5'>
                            <div className='text-[#4B5563] font-normal'>
                                22 August 2024
                            </div>
                            <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                                Instagram
                            </div>
                        </div>
                        <div className='text-[#111827] font-semibold text-[20px] leading-7  group-hover:text-gray-600'>
                            Diversify your investments and income sources.
                        </div>
                    </div>
                </a>
                <a href='https://www.facebook.com/share/p/oiuX4YVLHnqg7P1z/' target='_blank' rel="noreferrer" className='flex group'>
                    <div className='flex flex-col gap-4'>
                        <div className='overflow-hidden rounded-[16px]'>
                            <img src={FacebookPost} alt='' className='w-full h-full object-cover rounded-[16px]' />
                        </div>
                        <div className='flex gap-3 text-[14px] leading-5'>
                            <div className='text-[#4B5563] font-normal'>
                                09 August 2024
                            </div>
                            <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                                Facebook
                            </div>
                        </div>
                        <div className='text-[#111827] font-semibold text-[20px] leading-7 group-hover:text-gray-600'>
                            Start building credit as soon as possible.
                        </div>
                    </div>
                </a>
            </div>
            <div></div>
        </div>
    )
}

export default SocialMediaPosts