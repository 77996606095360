import React from "react";
import Logo from '../assets/images/Headline.png';
import CreditCard from '../assets/images/credit_card.png';
import Savings from '../assets/images/savings.png';
import Home from '../assets/images/home.png';
import Diamond from '../assets/images/diamond.png';
import Technology from '../assets/images/technology.png';
import Pallete from '../assets/images/palette.png';

const LeftSideBar = ({ isOpen }) => {
    return (
        <div className={`md:block w-full md:w-[25%] bg-[#F2F3F9] m-[10px] rounded-[12px] p-3 transition-transform transform ${isOpen ? 'block translate-x-0' : 'hidden -translate-x-full'} md:translate-x-0`}>
            <img src={Logo} alt='logo' className="" />
            <div className="w-full mt-2 h-[60px] bg-[#E6E8EE] rounded-[12px] text-[#30628C] flex items-center px-3">
                Dashboard
            </div>
            <div className="flex flex-col px-3 mt-3">
                <div className="flex space-x-3 items-center px-2 py-3">
                    Assets
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={CreditCard} alt='creditcard' />
                    </div>
                    <div>
                        Banks and Credit Cards
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={Savings} alt='savings' />
                    </div>
                    <div>
                        Investments and Loans
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={Home} alt='home' />
                    </div>
                    <div>
                        Property and Vehicles
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={Diamond} alt='diamond' />
                    </div>
                    <div>
                        Precious Metals, Jewels
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={Technology} alt='technology' />
                    </div>
                    <div>
                        Technology and Hardware
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3">
                    <div className="w-[28px]">
                        <img src={Pallete} alt='pallete' />
                    </div>
                    <div>
                        Art and Collectibles
                    </div>
                </div>
                <div className="flex space-x-3 items-center px-2 py-3 border-t-2 border-[#E6E8EE]">
                    Transactions
                </div>
                <div className="flex space-x-3 items-center px-2 py-3 border-t-2 border-[#E6E8EE]">
                    Help
                </div>
                <div className="flex space-x-3 items-center px-2 py-3 border-t-2 border-[#E6E8EE]">
                    Account
                </div>
            </div>
        </div>
    )
}

export default LeftSideBar;
