import React from "react";
import Modal from "react-modal";
import CheckCircle from '../assets/images/Icons/CheckCircle.svg';

const RegistrationSuccess = (props) => {
  function closeModal() {
    props.setShowRegisterSuccess(false);
    document.body.classList.remove("modal-open");
  }

  function afterOpenModal() {
    document.body.classList.add("modal-open");
  }

  function handleModalStates() {
    // props.setShowModal(true);
    closeModal();
  }

  const customStyles = {
    content: {
      top: "35%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: "15px",
      border: 0,
      backgroundColor: "#F2F3F9",
      borderRadius: "10px 10px 10px 10px",
      margin: "0 30px 0 0px",
      zIndex: 1001
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.80)",
      zIndex: 1000
    },
  };
  return (
    <div>
      <Modal
        isOpen={props.showRegisterSuccess}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="flex flex-col items-center justify-center h-[12.75rem] w-[20rem] md:h-[14.75rem] md:w-[24rem] gap-6 px-6 md:px-8 py-4">
          <div className="flex flex-col items-center justify-center gap-4">
            <div className='bg-[#DCFCE7] h-8 w-8 md:h-[4.438rem] md:w-[4.438rem] rounded-full p-[0.3rem] lg:p-[0.888rem] flex items-center justify-center'>
              <img src={CheckCircle} alt='' className='flex items-center justify-center w-[35.5px] h-[35.5px]' />
            </div>
            <div className='text-[#111827] font-semibold text-[18px] leading-7'>
              Waitlisted successfully!
            </div>
            <div className='text-[#4B5563] font-normal text-[14px] text-center leading-5'>
              Thanks for joining our waitlist! We'll keep you posted as we progress.
            </div>
          </div>
          <div className='bg-[#0072DE] px-[12px] py-[8px] md:px-[18px] md:py-[10px] h-fit w-full rounded-[8px] text-[#FFFFFF] text-center cursor-pointer' onClick={handleModalStates}>
            Okay
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegistrationSuccess;