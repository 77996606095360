import React from 'react'

const PolicyReview = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Policy Review, Updates & Applicability
            </div>
            <p>
                This Privacy Policy applies to Personal Information (including any information/documents/details that relates to a natural or a legal person, which either directly or indirectly in combination with other information available with Networth Tracker, can identify such person) and other data as mentioned in the 'Information Collected' section of this Privacy Policy.
            </p>
            <p>
                This Privacy Policy is intended to govern the use of Networth Tracker Services by our Customers (including, without limitation, those who use Networth Tracker Services in the course of their trade or business), unless otherwise agreed through contract.
            </p>
            <p>
                We may amend this Privacy Policy at any time by posting a revised version on our Platform. The revised version will be effective as of the published effective date. We will provide you with prior notice of changes to this Privacy Policy where required by applicable laws. Upon completion of the notice period, you, as a Customer, will be deemed to have expressly consented to all amendments to this Privacy Policy.
            </p>
            <p>
                At Networth Tracker, we are committed to protecting your personal information and respecting your privacy. This document (hereinafter referred to as “Privacy Policy”) describes practices related to the storage, use, processing, and disclosure of your Personal Information that may be accessed by us or that you may choose to share with us when you use our Platform or Services. This Privacy Policy sets out the basis on which any Personal Information we collect from you or that you provide to us will be processed by us. The statements & procedures mentioned under this Privacy Policy must be read in conjunction with our general terms & conditions available here (“User Agreement”).
            </p>
            <p>
                You accept this Privacy Policy when you visit/use our Platform, sign up for, access, or use our products, services, content, features, technologies, or functions offered on our Platform and all related sites, applications, and services (collectively referred to as “Networth Tracker Services”).
            </p>
            <p>
                By providing us with consent to process your Personal Information, you acknowledge that we will collect, store, use, and disclose your Personal Information in accordance with this Privacy Policy.
            </p>
        </div>
    )
}

export default PolicyReview