import React, { useState } from "react";
import GridLeft from "../assets/images/GridLeft.png";
import GridRight from "../assets/images/GridRight.png";

const ProductsServices = () => {
  const [activeTab, setActiveTab] = useState("products");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="px-5 md:px-14 xl:px-[120px] pt-12 md:pt- pb-10 md:pb-[72px] bg-[#111827] relative">
      <div className="text-[#FFFFFF] text-center font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8] w-full lg:w-[100%] mb-2">
        <h1>
          Track Your <span className="text-blue-secondary">Net Worth</span>
        </h1>
      </div>
      <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center mb-10">
        <p>
          Our comprehensive products and services provide you with powerful
          tools for seamless wealth tracking, helping you monitor, manage, and
          optimize your financial growth with ease.
        </p>
      </div>
      <div className="relative grid grid-cols-2 rounded-3xl gap-1 py-1 bg-blue-tertiary mb-10">
        <div
          className={`absolute top-0 left-0 h-full w-[50%] bg-blue-primary rounded-3xl transition-transform duration-300 ${
            activeTab === "products" ? "translate-x-0" : "translate-x-full"
          }`}
        />
        <div
          className={`relative py-3 cursor-pointer text-center m-auto rounded-3xl h-9 w-full flex items-center justify-center z-10 ${
            activeTab === "products"
              ? "text-white font-bold"
              : "text-blue-primary"
          }`}
          onClick={() => handleTabClick("products")}
        >
          Products
        </div>
        <div
          className={`relative py-3 cursor-pointer text-center m-auto rounded-3xl h-9 w-full flex items-center justify-center z-10 ${
            activeTab === "services"
              ? "text-white font-bold"
              : "text-blue-primary"
          }`}
          onClick={() => handleTabClick("services")}
        >
          Services
        </div>
      </div>
      <div className="relative z-10">
        <div className="absolute lg:left-0 top-0 lg:top-4 opacity-15 z-0">
          <img src={GridLeft} alt="" />
        </div>
        <div className="absolute bottom-0 lg:right-0 lg:top-4 opacity-15 z-0">
          <img src={GridRight} alt="" />
        </div>

        {/* Products Content */}
        {activeTab === "products" && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Comprehensive Account Aggregation</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>
                      Track Your Net Worth: Monitor your overall financial
                      health.
                    </li>
                    <li>
                      Analyze Your Investments: Gain insights into your stock,
                      mutual fund, and real estate holdings.
                    </li>
                    <li>
                      Manage Your Expenses: Categorize and track your spending
                      habits.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Integrated Financial Management</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>
                      Personalized Insights: Receive tailored recommendations to
                      improve your financial decisions.
                    </li>
                    <li>
                      Budgeting and Planning: Set financial goals and create
                      actionable plans.
                    </li>
                    <li>
                      Tax Optimization: Minimize your tax liability with smart
                      strategies.
                    </li>
                    <li>
                      Insurance Advisory: Protect your future with the right
                      insurance coverage.
                    </li>
                    <li>
                      Estate Planning: Secure your legacy for generations to
                      come.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Services Content */}
        {activeTab === "services" && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Net Worth Tracking and Wealth Tracking</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Monitor financial health</li>
                    <li>Make informed financial decisions</li>
                    <li>Set and achieve goals</li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Net Worth Calculator</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Calculate Net Worth</li>
                    <li>Track Assets and Liabilities</li>
                    <li>Monitor your financial position</li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Expense Optimization</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Identify savings opportunities</li>
                    <li>Optimize budget</li>
                    <li>Track spending habits</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 items-center mt-8">
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Cash Flow Management</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Maximize cash flow</li>
                    <li>Optimize investment portfolio</li>
                    <li>Manage debt</li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Insurance</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Protect assets</li>
                    <li>Minimize financial risk</li>
                    <li>Secure family's future</li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Retirement Planning</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Plan for a comfortable retirement</li>
                    <li>Maximize retirement savings</li>
                    <li>Secure financial future</li>
                  </ul>
                </div>
              </div>
              <div className="border rounded-[10px] h-full flex flex-col p-4 lg:p-6 gap-6 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-2xl leading-[30px]">
                  <h3>Financial Budgeting</h3>
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  <ul className="list-disc pl-5">
                    <li>Create a personalized budget</li>
                    <li>Track spending</li>
                    <li>Save money</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsServices;
