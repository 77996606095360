import React from "react";
import BlueDot from "../components/BlueDot";
import userOne from "../assets/images/users/user-1.jpg";
import userTwo from "../assets/images/users/user-2.jpg";
import userThree from "../assets/images/users/user-3.jpg";
import userFour from "../assets/images/users/user-4.jpg";
import Sebi from "../assets/images/Logos/Sebi.svg";
import Saafe from "../assets/images/Logos/Saafe.svg";
import Sahmati from "../assets/images/Logos/Sahmati.svg";
import Rebit from "../assets/images/Logos/Rebit.svg";
import DPDPA from "../assets/images/Logos/DPDPA.svg";
// import Illustration from '../assets/images/temp/Illustration-Area.png';
import EmailInput from "./EmailInput";
import Clock from "../assets/images/Icons/Clock.png";
import Analytic from "../assets/images/Icons/Analytic.png";
import userFive from "../assets/images/users/user-5.png";
import CreditCardIcon from "../assets/images/Icons/CreditCard.png";
import Basket from "../assets/images/Icons/Basket.png";
import Download from "../assets/images/Icons/Download.png";
import CreditCardOne from "../assets/images/Credit-Card-1.svg";

const NetworthTracker = () => {
  return (
    <div className="flex flex-col xl:flex-row px-5 md:px-14 xl:px-[120px] py-1 md:py-4 xl:py-10 xl:pb-20">
      <div className="flex flex-col w-full lg:w-[70%]">
        <div className="flex flex-col space-y-2 md:space-y-4">
          <div className="flex items-center justify-center border rounded-[10px] px-2 py-1 w-fit gap-2">
            <BlueDot />
            <div className="text-[#0A0A0A] font-medium text-[12px] leading-[18.6px]">
              <h6>Networth Tracker - All Your Finances, One Place</h6>
            </div>
          </div>
          <div className="font-bold text-[36px] md:text-[48px] leading-[48px] md:leading-[64px] -tracking-[0.96px] w-full lg:w-[90%]">
            <h1>Empower Your Wealth: Track, Automate, Prosper!</h1>
          </div>
          <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-full lg:w-[80%]">
            <p>
              Tracking your finances is just the start. Unlock more with
              seamless net worth tracking with our advanced AI-powered
              analytics, advisory and more.
            </p>
          </div>
          <div className="flex flex-col">
            <EmailInput text="Join Waitlist" id="waitlist-share" />
            <div className="flex py-4 space-x-4">
              <div className="flex -space-x-2">
                <img
                  src={userOne}
                  alt=""
                  className="w-10 aspect-square rounded-full"
                />
                <img
                  src={userTwo}
                  alt=""
                  className="w-10 aspect-square rounded-full"
                />
                <img
                  src={userThree}
                  alt=""
                  className="w-10 aspect-square rounded-full"
                />
                <img
                  src={userFour}
                  alt=""
                  className="w-10 aspect-square rounded-full"
                />
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-[20px] flex items-center justify-center">
                Join our waitlisted users
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:space-y-8">
          <div className="text-[#737373] font-medium text-[16px] leading-[25.6px] mt-2">
            Trusted by RBI, SEBI and DPDPA
          </div>
          <div className="flex items-center justify-center">
            <div className="grid grid-cols-3 md:grid-cols-5 gap-6 md:gap-8 lg:gap-11">
              <div className="flex items-center justify-center">
                <img
                  src={Sebi}
                  alt="Sebi"
                  className="max-w-full max-h-full w-auto h-auto object-contain"
                />
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={Sahmati}
                  alt="Sahmati"
                  className="max-w-full max-h-full w-auto h-auto object-contain"
                />
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={DPDPA}
                  alt="DPDPA"
                  className="max-w-full max-h-full w-auto h-auto object-contain"
                />
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={Rebit}
                  alt="Rebit"
                  className="max-w-full max-h-full w-auto h-auto object-contain"
                />
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={Saafe}
                  alt="Saafe"
                  className="max-w-full max-h-full w-auto h-auto object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full xl:w-[50%] mt-20 mb-12 xl:mt-0 xl:mb-0">
        <div className="bg-[#FFFFFF] rounded-lg shadow-2xl p-4 h-fit md:h-[420px] flex flex-col items-center relative">
          <div className="w-full">
            <div className="flex justify-between p-4">
              <div className="flex space-x-2">
                <img src={userFive} alt="" />
                <div className="flex flex-col">
                  <div className="text-[#111827] font-medium text-[18px] leading-7">
                    Anisha Khanna
                  </div>
                  <div className="flex space-x-3 text-[#666666] font-normal text-[14px] leading-[21.7px]">
                    <div>5678</div>
                    <div>• • • •</div>
                    <div>1234</div>
                    <div>8824</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={CreditCardIcon} alt="" />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-6 p-4">
              <img src={CreditCardOne} alt="" />
              <div className="flex flex-col justify-center">
                <div className="text-[#666666] font-normal text-[12px] leading-5">
                  Card Balance
                </div>
                <div className="flex space-x-4">
                  <div className="text-[#111827] font-bold text-[32px] leading-10">
                    ₹2,00,656.20
                  </div>
                  <div className="flex items-center">
                    <div className="text-[#40C4AA] font-medium text-[11px] leading-3 bg-[#DDF3EF] rounded-full px-[6px] py-[2px] flex items-center justify-center w-9 h-4">
                      +5%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-4">
              <div className="flex">
                <div className="flex items-center justify-center">
                  <img src={Basket} alt="" />
                </div>
                <div className="text-[#111827] font-medium text-[16px] leading-[25.6px] px-4 py-1">
                  Recent Transactions
                </div>
              </div>
              <div className="border rounded-[8px] px-1 md:px-3 py-1 text-[#111827] font-medium text-[10px] h-fit w-fit leading-6">
                See All
              </div>
            </div>
          </div>
          <div className="flex items-center px-[6px] py-[6px] border bg-[#FFFFFF] rounded-[27.73px] h-[52px] w-fit absolute -top-6 right-0 md:-right-16 shadow-lg">
            <div className="bg-[#DBEEFF] rounded-[27.73px] p-[8.67px] h-[41.6px] w-[41.6px]">
              <img src={Clock} alt="" />
            </div>
            <div className="text-[#111827] font-medium text-[13.87px] leading-[20.8px] p-4 flex items-center justify-center">
              100% Secured Data
            </div>
          </div>
          <div className="flex items-center px-[5px] py-[5px] border bg-[#FFFFFF] rounded-[23.47px] h-11 w-fit absolute -bottom-6 md:bottom-4 left-0 md:-left-16 z-10 shadow-lg">
            <div className="bg-[#DBEEFF] rounded-[23.47px] p-[7.33px] h-[35.2px] w-[35.2px]">
              <img src={Analytic} alt="" />
            </div>
            <div className="text-[#111827] font-medium text-[11.73px] leading-[17.6px] p-4 flex items-center justify-center">
              Advanced analytic
            </div>
          </div>
          <div className="md:absolute -bottom-20 w-[100%] md:w-[90%]">
            <div className="border bg-[#FFFFFF] p-5 rounded-lg m-3">
              <div className="flex justify-between items-center w-full">
                <div className="text-[#666666] font-normal text-[12px] leading-[18.6px]">
                  Transfer to Ramesh
                </div>
                <div className="flex flex-col md:flex-row space-x-2 md:space-x-6">
                  <div className="text-[#666666] font-normal text-[12px] leading-[18.6px] flex items-center justify-center">
                    July 18, 2024
                  </div>
                  <div className="md:block bg-[#F5F5F5] h-6 rounded-full px-1 md:px-[6px] py-[2px] text-[#111827] font-normal text-[12px] leading-5 w-fit">
                    11:45 AM
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#111827] font-bold text-[24px] leading-[30px]">
                  ₹12,445.50
                </div>
                <div className="flex items-center justify-center">
                  <div className="h-4 w-4">
                    <img src={Download} alt="" />
                  </div>
                  <div className="text-[#666666] font-normal text-[12px] leading-[18.6px] p-2">
                    Download
                  </div>
                </div>
              </div>
            </div>
            <div className="border bg-[#FFFFFF] p-5 rounded-lg m-3 shadow-[0px_10px_20px_-10px_rgba(0,0,0,0.3)]">
              <div className="flex justify-between items-center">
                <div className="text-[#666666] font-normal text-[12px] leading-[18.6px]">
                  Transfer to VishalMart
                </div>
                <div className="flex flex-col md:flex-row space-x-2 md:space-x-6">
                  <div className="text-[#666666] font-normal text-[12px] leading-[18.6px] flex items-center justify-center">
                    July 16, 2024
                  </div>
                  <div className="md:block bg-[#F5F5F5] h-6 rounded-full px-1 md:px-[6px] py-[2px] text-[#111827] font-normal text-[12px] leading-5 w-fit">
                    08:00 PM
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#111827] font-bold text-[24px] leading-[30px]">
                  ₹8212.64
                </div>
                <div className="flex items-center justify-center">
                  <div className="h-4 w-4">
                    <img src={Download} alt="" />
                  </div>
                  <div className="text-[#666666] font-normal text-[12px] leading-[18.6px] p-2">
                    Download
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworthTracker;
