import React from 'react'

const Information = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Information Collected
            </div>
            <p>
                We collect the following personal information in compliance with applicable law to provide you with Networth Tracker Services:
            </p>
            <div>
                <ul className='list-disc ml-4 xl:ml-6'>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Device Information: </span><span className='text-[#4B5563] font-normal'>We collect data sent by your computer, mobile phone, or other access device. This includes information such as pages accessed, IP address, device ID, device type, geolocation, mobile network information, traffic to and from sites, referral URLs, and other standard web log data. This data is used for analytics.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>App Data: </span><span className='text-[#4B5563] font-normal'>We collect, transmit, and store information about installed applications on our secured server.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Cookies: </span><span className='text-[#4B5563] font-normal'>We collect anonymous information through cookies and web beacons. We may collect and store information you provide when using Networth Tracker Services, such as when filling out forms, updating account information, participating in discussions, or contacting us.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Transaction Data: </span><span className='text-[#4B5563] font-normal'>This includes details of transactions conducted through the Platform, including email information. We collect this data after obtaining necessary permissions in accordance with applicable law.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Financial Data: </span><span className='text-[#4B5563] font-normal'>This includes information as defined under the Master Direction Account Aggregator (Reserve Bank) Directions as updated or amended from time to time like bank account details, bank transaction details, insurance, stocks, etc. including any financial information you may expressly share with us based on your consent.</span>
                    </li>
                    <li className='text-[#4B5563]'><span className='text-[#4B5563] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7'>Other Personal Information: </span><span className='text-[#4B5563] font-normal'>We collect details such as your name, address, phone number, date of birth, employment status, and other relevant details when you use Networth Tracker Services.</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Information