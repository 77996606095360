import React from "react";

const AboutUs = () => {
  return (
    <div className="px-5 lg:px-[120px]">
      <div className="flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4">
        <div className="text-blue-primary font-semibold text-[16px] leading-6 text-center">
          About us
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] text-center">
          Hello, we're Networth Tracker!
        </div>
        <div className="text-[#4B5563] text-center font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-full lg:w-[60%]">
          Effortlessly track, manage, and optimize your finances online with
          automation. Our platform streamlines financial tasks, giving you
          real-time insights and control over your wealth.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
