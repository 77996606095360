import React from 'react'

const Cookies = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Cookies Policy
            </div>
            <p>
                We use cookies and similar technologies to analyze Platform usage, measure promotional effectiveness, and improve the user experience. Cookies are small files placed on your device that help identify you and enable features. You can manage cookies through your device settings, though some features may be limited if cookies are disabled.
            </p>
        </div>
    )
}

export default Cookies