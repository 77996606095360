import React from 'react'

const BlueDot = () => {
  return (
    <div 
        className='flex items-center justify-center border-[#B3DAFF] border-2 rounded-full bg-[#0079C0] w-2 h-2'
    >
    </div>
  )
}

export default BlueDot;