import React from 'react';
import NavBar from '../components/NavBar.js';
import Contact from '../components/Contact';
import Form from '../components/Form.js';
import Footer from '../components/Footer.js';
import { Helmet } from 'react-helmet-async';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Networth Tracker - Contact Us</title>
        <meta name="description" content="Get in touch with us for any inquiries, support, or feedback regarding Networth Tracker. Our team is here to assist you." />
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <div className='tracking-wide'>
        <NavBar />
        <Contact />
        <Form />
        <Footer />
      </div>
    </>
  )
}

export default ContactUs;