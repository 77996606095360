import React from "react";
import Modal from "react-modal";
import CheckCircle from '../assets/images/Icons/CheckCircle.svg';

const AlreadyWaitlisted = (props) => {
    function closeModal() {
        props.setShowAlreadyWaitlisted(false);
        document.body.classList.remove("modal-open");
    }

    function afterOpenModal() {
        document.body.classList.add("modal-open");
    }

    function handleModalStates() {
        closeModal();
    }

    const customStyles = {
        content: {
            top: "35%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            padding: "15px",
            border: 0,
            backgroundColor: "#F2F3F9",
            borderRadius: "10px 10px 10px 10px",
            margin: "0 30px 0 0px"
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.80)",
            zIndex: 1000
        },
    };
    
    return (
        <div>
            <Modal
                isOpen={props.showAlreadyWaitlisted}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="flex flex-col items-center justify-center h-[16rem] w-[20rem] md:h-[19.188rem] md:w-[24rem] gap-6 px-4 md:px-6 py-6">
                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className='bg-[#DCFCE7] h-8 w-8 md:h-[4.438rem] md:w-[4.438rem] rounded-full p-[0.3rem] lg:p-[0.888rem] flex items-center justify-center'>
                            <img src={CheckCircle} alt='' className='flex items-center justify-center w-[35.5px] h-[35.5px]' />
                        </div>
                        <div className='text-[#111827] font-semibold text-[18px] leading-7'>
                            Already on the Waitlist!
                        </div>
                        <div className='text-[#4B5563] font-normal text-[14px] text-center leading-5'>
                            You're already on our waitlist. Thank you for your patience! We'll notify you as soon as we're ready to welcome more users.
                        </div>
                    </div>
                    <div className='bg-[#0072DE] px-[12px] py-[8px] md:px-[18px] md:py-[10px] h-fit w-full rounded-[8px] text-[#FFFFFF] text-center cursor-pointer' onClick={handleModalStates}>
                        Okay
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AlreadyWaitlisted;
