import React, { useState } from "react";
import LeftSideBar from "../components/LeftSideBar";
import DashBoardLogo from "../components/DashBoardLogo";
import ShowOptions from "../modals/ShowOptions";
import ShowModal from "../modals/ShowModal";
import CustomLineChart from "../components/LineChart";
import PieCharts from "../components/PieChart";
import data from "../components/data/PieChartData.json";

const Dashboard = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const calculateFinalAmounts = (transactions, filterType = 'all') => {
    const bankAmounts = {};

    transactions.forEach(transaction => {
      const { bankName, type, amount } = transaction;

      if (!bankAmounts[bankName]) {
        bankAmounts[bankName] = 0;
      }
      
      if (filterType === 'all' || filterType === type) {
        if (type === 'debit') {
          bankAmounts[bankName] -= parseFloat(amount);
        } else if (type === 'credit') {
          bankAmounts[bankName] += parseFloat(amount);
        }
      }
    });

    return bankAmounts;
  };

  const getChartData = () => {
    const finalAmounts = calculateFinalAmounts(data, selectedFilter);
    return Object.keys(finalAmounts).map((key) => ({
      name: key,
      value: Math.abs(finalAmounts[key]),
      originalValue: Number(finalAmounts[key])
    }));
  };

  return (
    <div className="h-screen w-screen flex">
      <LeftSideBar isOpen={isSidebarOpen} />
      <div className={`${isSidebarOpen ? "hidden" : "block"} w-full md:w-[75%] m-[10px] h-fit`}>
        <div className="w-full">
          <DashBoardLogo
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <div className="text-[#181C20] font-400 text-[16px] tracking-[0.5px] py-2 w-full">
            To track your net worth, begin by connecting a bank or credit card
            where most of your spending happens.
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => setShowOptions(true)}
              className="bg-[#9CCBFB] px-[20px] py-2 rounded-[100px]"
            >
              Let's get started
            </button>
          </div>
          <div className="w-full font-500 text-[12px] text-[#BA1A1A] leading-[16px] tracking-[0.5px] mt-3 py-1">
            THIS IS A SAMPLE DASHBOARD
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div
              className="flex border py-[2px] cursor-pointer"
              onClick={() => setSelectedFilter('all')}
            >
              <div className="w-[6px] h-full bg-[#0079C1] mr-4 rounded-[2px]"></div>
              <div className="py-2">
                <div className="text-[#42474E] text-[16px] font-500 leading-[24px] tracking-[0.1px]">
                  My Balance
                </div>
                <div className="text-[#15223C] font-sans font-600 text-[20px] leading-[24.38px]">
                  $128,320
                </div>
              </div>
            </div>
            <div
              className="flex border py-[2px] cursor-pointer"
              onClick={() => setSelectedFilter('credit')}
            >
              <div className="w-[6px] h-full bg-[#2DD683] mr-4 rounded-[2px]"></div>
              <div className="py-2">
                <div className="text-[#42474E] text-[16px] font-500 leading-[24px] tracking-[0.1px]">
                  Income
                </div>
                <div className="text-[#15223C] font-sans font-600 text-[20px] leading-[24.38px]">
                  $128,320
                </div>
              </div>
            </div>
            <div className="flex border py-[2px]">
              <div className="w-[6px] h-full bg-[#FED142] mr-4 rounded-[2px]"></div>
              <div className="py-2">
                <div className="text-[#42474E] text-[16px] font-500 leading-[24px] tracking-[0.1px]">
                  Savings
                </div>
                <div className="text-[#15223C] font-sans font-600 text-[20px] leading-[24.38px]">
                  $128,320
                </div>
              </div>
            </div>
            <div
              className="flex border py-[2px] cursor-pointer"
              onClick={() => setSelectedFilter('debit')}
            >
              <div className="w-[6px] h-full bg-[#FA8B3A] mr-4 rounded-[2px]"></div>
              <div className="py-2">
                <div className="text-[#42474E] text-[16px] font-500 leading-[24px] tracking-[0.1px]">
                  Expense
                </div>
                <div className="text-[#15223C] font-sans font-600 text-[20px] leading-[24.38px]">
                  $128,320
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-8 mt-4 h-fit">
          <div className="border p-3 w-[70%]">
            <CustomLineChart />
          </div>
          <div className="border h-auto w-[30%] p-3">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-[22px] text-[#15223C]">
                My Savings
              </div>
              <div className="text-[#0079C1] tracking-wide">View all</div>
            </div>
            <div className="flex flex-col space-y-3 my-4 text-[14px]">
              <div className="flex justify-between items-center gap-3">
                <div className="flex-1">
                  Gaming PC
                  <div className="bg-gray-100 h-1 w-full rounded-lg mt-2 relative">
                    <div
                      className="bg-blue-500 h-full rounded-lg absolute top-0 left-0"
                      style={{ width: `${(309 / 1000) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="pr-2">Rs 309</div>
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="flex-1">
                  New house
                  <div className="bg-gray-100 h-1 w-full rounded-lg mt-2 relative">
                    <div
                      className="bg-blue-500 h-full rounded-lg absolute top-0 left-0"
                      style={{ width: `${(950 / 1000) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="pr-2">Rs 950</div>
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="flex-1">
                  Summer trip
                  <div className="bg-gray-100 h-1 w-full rounded-lg mt-2 relative">
                    <div
                      className="bg-blue-500 h-full rounded-lg absolute top-0 left-0"
                      style={{ width: `${(550 / 1000) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="pr-2">Rs 550</div>
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="flex-1">
                  Wedding
                  <div className="bg-gray-100 h-1 w-full rounded-lg mt-2 relative">
                    <div
                      className="bg-blue-500 h-full rounded-lg absolute top-0 left-0"
                      style={{ width: `${(620 / 1000) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="pr-2">Rs 620</div>
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="flex-1">
                  Top up game
                  <div className="bg-gray-100 h-1 w-full rounded-lg mt-2 relative">
                    <div
                      className="bg-blue-500 h-full rounded-lg absolute top-0 left-0"
                      style={{ width: `${(170 / 1000) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="pr-2">Rs 170</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 border">
          <PieCharts chartData={getChartData()} />
        </div>
        {showOptions && (
          <ShowOptions
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            setShowModal={setShowModal}
          />
        )}
        {showModal && (
          <ShowModal showModal={showModal} setShowModal={setShowModal} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
