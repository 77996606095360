import React from 'react';
import Linkedin from '../assets/images/Icons/Linkedin.png';
import Facebook from '../assets/images/Icons/Facebook.png';
import Twitter from '../assets/images/Icons/Twitter.png';
import Instagram from '../assets/images/Icons/Instagram.png';

const SocialMediaHandles = () => {
    return (
        <div className='flex flex-col bg-[#F9FAFB] rounded-[16px] p-8 gap-8'>
            <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                Connect with us
            </div>
            <div className='flex flex-col gap-4'>
                <a href='https://twitter.com/NWTracker/' target='_blank' rel="noreferrer" className='flex'>
                    <div className='flex gap-2'>
                        <div className='flex items-center justify-center'>
                            <img src={Twitter} alt='' />
                        </div>
                        <div className='text-[#0D0D12] font-medium text-[18px] leading-6 tracking-[0.18px] text-center hover:text-gray-600 hover:underline'>
                            Twitter
                        </div>
                    </div>
                </a>
                <a href='https://www.facebook.com/networth.tracker/' target='_blank' rel="noreferrer" className='flex'>
                    <div className='flex gap-2'>
                        <div className='flex items-center justify-center'>
                            <img src={Facebook} alt='' />
                        </div>
                        <div className='text-[#0D0D12] font-medium text-[18px] leading-6 tracking-[0.18px] text-center hover:text-gray-600 hover:underline'>
                            Facebook
                        </div>
                    </div>
                </a>
                <a href='https://www.instagram.com/networth.tracker/' target='_blank' rel="noreferrer" className='flex'>
                    <div className='flex gap-2'>
                        <div className='flex items-center justify-center'>
                            <img src={Instagram} alt='' />
                        </div>
                        <div className='text-[#0D0D12] font-medium text-[18px] leading-6 tracking-[0.18px] text-center hover:text-gray-600 hover:underline'>
                            Instagram
                        </div>
                    </div>
                </a>
                <a href='https://www.linkedin.com/company/networth-tracker/' target='_blank' rel="noreferrer" className='flex'>
                    <div className='flex gap-2'>
                        <div className='flex items-center justify-center'>
                            <img src={Linkedin} alt='' />
                        </div>
                        <div className='text-[#0D0D12] font-medium text-[18px] leading-6 tracking-[0.18px] text-center hover:text-gray-600 hover:underline'>
                            Linkedin
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default SocialMediaHandles