import React, { useState } from "react";
import { postQuery } from "../api/discover";
import toast, { Toaster } from "react-hot-toast";
import FormImage from "../assets/images/FormImage.svg";
import ContactUsModal from "../modals/ContactUsModal";

const Form = () => {
  const [queryData, setQueryData] = useState({
    name: "",
    email: "",
    query: "",
    type: "FAQ",
  });

  const [state, setState] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(queryData.email)) {
      toast.error("Invalid Email");
      setQueryData({
        name: "",
        email: "",
        query: "",
      });
      return;
    }

    setState(true);
    try {
      const res = await postQuery(queryData);
      if (res.status === 200) {
        setQueryData({
          name: "",
          email: "",
          query: "",
        });
        setShowContactUs(true);
      } else {
        toast.error("Failed to send Message. Please try again.");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong. Please try again.");
    } finally {
      setState(false);
    }
  }

  return (
    <div className="px-5 xl:px-[120px] py-4 md:py-10 lg:py-24">
      <Toaster />
      <div className="flex flex-col md:flex-row gap-8 md:justify-between w-full">
        <div className="md:w-[50%] flex items-center justify-center">
          <img
            src={FormImage}
            alt=""
            className="hidden md:flex items-center justify-center"
          />
        </div>
        <div className="flex flex-col gap-4 lg:gap-12 md:w-[50%] px-4">
          <div className="flex flex-col gap-4">
            <div className="text-[#111827] font-semibold text-[36px] leading-[44px] -tracking-[0.72px]">
              Reach out to us
            </div>
            <div className="text-[#737373] font-normal text-[18px] leading-7">
              Contact us for questions, feedback, or inquiries.
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="w-full mb-0">
              <label
                className="block tracking-wide text-[#737373] text-[14px] leading-5 font-medium mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                name="name"
                type="text"
                placeholder="Your Name"
                value={queryData.name}
                onChange={(e) =>
                  setQueryData({
                    ...queryData,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="w-full mb-0">
              <label
                className="block tracking-wide text-[#737373] text-[14px] leading-5 font-medium mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                name="email"
                type="email"
                placeholder="Your Email"
                value={queryData.email}
                onChange={(e) =>
                  setQueryData({
                    ...queryData,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="w-full">
              <label
                className="block tracking-wide text-[#737373] text-[14px] leading-5 font-medium mb-2"
                htmlFor="message"
              >
                Your Query
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-300 resize-none"
                name="query"
                placeholder="Write Your Query"
                value={queryData.query}
                onChange={(e) =>
                  setQueryData({
                    ...queryData,
                    [e.target.name]: e.target.value,
                  })
                }
                required
                rows={4}
              ></textarea>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                className="bg-blue-primary hover:bg-blue-secondary transition-all rounded-[8px] h-fit w-full px-[18px] py-[10px] text-[#FFFFFF] font-medium text-[16px] leading-6 hover:cursor-pointer"
                onClick={handleSubmit}
                disabled={state}
                id="contactUs-share"
              >
                {state ? "Submitting..." : "Send message"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showContactUs && (
        <ContactUsModal
          showContactUs={showContactUs}
          setShowContactUs={setShowContactUs}
        />
      )}
    </div>
  );
};

export default Form;
