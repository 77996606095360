import React from "react";
import KhyatiAvatar from "../assets/images/Avatars/KhyatiAvatar.png";
import ImgOne from "../assets/images/BlogPageFour/ImgOne.png";

const BlogPageFourInfo = () => {
  return (
    <div className="px-5 xl:px-[120px]">
      <div className="flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4">
        <div className="text-blue-primary text-center font-semibold">
          Taxation
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center">
          Navigating the Gift Maze: Understanding Gift Taxation in India
        </div>
        <div className="text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center px-6 xl:px-10">
          Understanding the rules around gift taxation can help you avoid
          unexpected tax burdens and ensure your kind gestures remain a joyous
          occasion for both giver and receiver
        </div>
        <div className="flex gap-4">
          <div className="flex items-center justify-center">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-[#111827] font-semibold text-[16px] leading-6">
              Khyati Gandhi
            </div>
            <div className="text-[#4B5563] font-normal text-[14px] leading-5">
              Product Designer
            </div>
          </div>
        </div>
        <div className="xl:w-[1024px] xl:h-[550px]">
          <img
            src={ImgOne}
            alt=""
            className="w-full h-full object-cover rounded-[8px]"
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52">
        <div className="flex flex-col gap-5">
          <p>
            Gifts are a heartwarming way to express love, appreciation, or
            simply lend a helping hand. But in India, things can get a little
            more complex when it comes to taxes. While there's no longer a
            separate Gift Tax Act, gifts do get factored into your income tax
            calculations. Let's break down the essentials to ensure your
            generous gesture doesn't land the recipient (or you!) in hot water
            with the taxman.
          </p>
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Gifts Under Rs. 50,000: Relax and Celebrate!
          </div>
          <p>
            This is the good news! Gifts up to Rs. 50,000 in a financial year
            (April 1st to March 31st) are exempt from income tax for the
            receiver. So, that birthday cheque for your spouse or a helping hand
            to your child for their education won't attract any tax.
          </p>
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Who Can You Shower with Tax-Free Gifts?
          </div>
          <p>
            Blood ties come with tax benefits! Gifts received from specified
            relatives are exempt from tax regardless of the amount. These
            include:
          </p>
          <div className="px-6">
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">Spouse</span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Parents (including biological, step-parents, and legally
                  adopted parents)
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Siblings (including biological, step-siblings, and adopted
                  siblings)
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">Grandchildren</span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">Grandparents</span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Lineal ascendants (parents of your parents, and so on)
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Lineal descendants (your children, grandchildren, and so on)
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Spouse's parents and siblings
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Gifts Beyond the Bloodline: Tread Carefully
          </div>
          <p>
            Gifts exceeding Rs. 50,000 from someone outside the specified
            relative circle are taxable in the hands of the recipient. The
            entire amount becomes part of your income and is taxed at your
            income tax slab rate under the head "Income from Other Sources."
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Special Occasions Get a Tax Break
          </div>
          <p>
            Even if the giver isn't a close relative, gifts received on specific
            occasions are exempt from tax. These include:
          </p>
          <div className="px-6">
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Wedding gifts (up to Rs. 50,000 from each person)
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Inheritance through a Will
                </span>
              </li>
              <li className="text-[#111827]">
                <span className="text-[#4B5563]">
                  Gifts in contemplation of death (when the giver expects to die
                  soon)
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Remember the Annual Limit is Cumulative
          </div>
          <p>
            The Rs. 50,000 exemption applies to the total value of gifts
            received from non-relatives in a financial year. So, if you receive
            Rs. 30,000 from a friend and Rs. 40,000 from another, both amounts
            are added together, and the entire Rs. 70,000 becomes taxable.
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Planning for Gifting Wisely
          </div>
          <div className="px-6">
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#111827] font-semibold leading-8">
                  Stagger Large Gifts:{" "}
                </span>
                <span className="text-[#4B5563]">
                  If you're planning a substantial gift to someone outside the
                  exempt circle, consider spreading it over multiple financial
                  years to stay within the Rs. 50,000 limit.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-4 xl:ml-6">
              <li className="text-[#111827]">
                <span className="text-[#111827] font-semibold leading-8">
                  Explore Alternative Tax-Saving Options:{" "}
                </span>
                <span className="text-[#4B5563]">
                  Investments in tax-saving instruments like PPF, ELSS Mutual
                  Funds, or Unit Linked Insurance Plans (ULIPs) can be a better
                  way to help someone financially without tax implications.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8">
            Conclusion
          </div>
          <p>
            Gifting is a wonderful gesture, but understanding the tax
            implications ensures both the giver and receiver are on the same
            page. By keeping these pointers in mind, you can navigate the gift
            maze smoothly and celebrate generosity the right way!
          </p>
          <p>
            <span className="text-[#111827] font-semibold leading-8">
              Disclaimer:{" "}
            </span>
            <span className="text-[#4B5563]">
              This blog is for informational purposes only and should not be
              considered as tax advice. Please consult a registered tax
              professional for personalized guidance.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPageFourInfo;
