import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlogTwo from '../assets/images/BlogPageFive/ImgOne.png';
import BlogThree from '../assets/images/BlogPageSix/ImgOne.png';

const Trending = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col gap-8'>
            <div className='text-[#111827] font-semibold text-[24px] leading-8'>
                Trending Now
            </div>
            <div className='flex flex-col lg:flex-row gap-8'>
                <div className='w-full lg:w-[50%] flex flex-col gap-4 group cursor-pointer' onClick={() => navigate('/blog-five')}>
                    <div>
                        <img src={BlogTwo} alt='' className='w-full h-full rounded-[16px] object-cover' />
                    </div>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            15 September 2024
                        </div>
                        <div className='border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                            Budgeting
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[20px] leading-7 group-hover:text-gray-600'>
                        Feeling Financially Frayed? Master the 50/30/20 Rule for Budgeting Bliss
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        The 50/30/20 rule offers a straightforward approach to budgeting that prioritizes your needs
                    </div>
                </div>

                <div className='w-full lg:w-[50%] flex flex-col gap-4 group cursor-pointer' onClick={() => navigate('/blog-six')}>
                    <div>
                        <img src={BlogThree} alt='' className='w-full h-full rounded-[16px] object-cover' />
                    </div>
                    <div className='flex gap-3 text-[14px] leading-5'>
                        <div className='text-[#4B5563] font-normal'>
                            25 September 2024
                        </div>
                        <div className=' border rounded-[20px] flex items-center justify-center text-[#374151] font-medium px-[10px] py-[2px] bg-[#F9FAFB]'>
                            Finance
                        </div>
                    </div>
                    <div className='text-[#111827] font-semibold text-[20px] leading-7 cursor-pointer group-hover:text-gray-600'>
                        Cracking the Dilemma: Why Personal Finance Matters in India
                    </div>
                    <div className='text-[#4B5563] font-normal text-[16px] leading-6'>
                        Understanding personal finance is your ticket to achieving dreams and securing a stable future
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trending