import React from "react";
import { useNavigate } from "react-router-dom";
import FooterLogo from "../assets/images/Logos/FooterLogo.png";
import Twitter from "../assets/images/Icons/Twitter.png";
import Facebook from "../assets/images/Icons/Facebook.png";
import Instagram from "../assets/images/Icons/Instagram.png";
import Linkedin from "../assets/images/Icons/Linkedin.png";

const FooterBottom = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#111827] flex flex-col px-5 lg:px-20 text-[#E5E7EB]">
      <div className="flex flex-col lg:flex-row gap-4 justify-between items-center border-b py-5 lg:py-10 border-[#FFFFFF] border-opacity-40 lg:border-opacity-10">
        <div className="h-full flex items-center w-fit">
          <img src={FooterLogo} alt="" />
        </div>
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 font-medium text-[16px] leading-6 text-center">
          <div
            className="cursor-pointer hover:text-gray-600 transition-all"
            onClick={() => navigate("/company")}
          >
            About Us
          </div>
          <a
            href="https://www.linkedin.com/company/networth-tracker/"
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <div className="cursor-pointer hover:text-gray-600 transition-all text-center w-full">
              Careers
            </div>
          </a>
          <div className="hidden cursor-default">Partnerships</div>
          <div
            className="cursor-pointer hover:text-gray-600 transition-all"
            onClick={() => navigate("/blogs")}
          >
            Blog
          </div>
          <div
            className="cursor-pointer hover:text-gray-600 transition-all"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </div>
        </div>
        <div className="flex gap-6">
          <a
            href="https://twitter.com/NWTracker/"
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <div className="cursor-pointer">
              <img src={Twitter} alt="" className="h-[22px]" />
            </div>
          </a>
          <a
            href="https://www.facebook.com/networth.tracker/"
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <div className="cursor-pointer">
              <img src={Facebook} alt="" className="h-[22px]" />
            </div>
          </a>
          <a
            href="https://www.instagram.com/networth.tracker/"
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <div className="cursor-pointer">
              <img src={Instagram} alt="" className="h-[22px]" />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/networth-tracker/"
            target="_blank"
            rel="noreferrer"
            className="flex"
          >
            <div className="cursor-pointer">
              <img src={Linkedin} alt="" className="h-[22px]" />
            </div>
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-between items-center lg:flex-row">
        <div className="font-normal text-[16px] leading-6 justify-center py-5 lg:py-10">
          © 2024 Networth Tracker Solutions Private Limited, Inc. All rights
          reserved.
        </div>
        <div
          className="font-normal text-[16px] leading-6 justify-center py-5 lg:py-10 cursor-pointer hover:text-gray-400 transition-all"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
