import React from 'react'

const Introduction = () => {
    return (
        <div className='flex flex-col gap-5 px-5 md:px-16 xl:px-[180px] justify-center'>
            <div className='text-[#111827] font-semibold text-[24px] md:text-[30px] leading-8 md:leading-[38px]'>
                Introduction
            </div>
            <p className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7'>
                <span className='font-semibold'> Networth Tracker Solutions Private Limited</span> and its related entities (collectively referred to as "We/Our/Us/Networth Tracker") provide various services to our customers ("Customer/You/Your/User") through the use of our website (https://networthtracker.in), our application available on the App Store named Networth Tracker, and any other websites or platforms that we may own, operate, or may operate in the future (collectively referred to as "Platform").
            </p>
        </div>
    )
}

export default Introduction