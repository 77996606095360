import React from "react";
import { useNavigate } from "react-router-dom";
import KhyatiAvatar from "../assets/images/Avatars/KhyatiAvatar.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogPost = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <div className="custom-next" />,
    prevArrow: <div className="custom-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <div className="px-5 md:px-14 xl:px-[120px] py-10 lg:py-24 flex flex-col gap-9 md:gap-11">
      <div className="flex flex-col gap-2 pb-6 lg:pb-11 border-b">
        <div className="text-blue-primary font-semibold text-[16px] leading-6">
          <h3>Our Blog</h3>
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px]">
          <h1>Latest Blog Posts</h1>
        </div>
        <div className="text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7">
          <p>Stay ahead of the curve with our latest blog releases</p>
        </div>
      </div>
      <Slider {...settings}>
        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-four")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              01 September 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Taxation
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            Navigating the Gift Maze: Understanding Gift Taxation in India
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            Understanding the rules around gift taxation can help you avoid
            unexpected tax burdens
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-five")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              15 September 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Budgeting
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            Feeling Financially Frayed? Master the 50/30/20 Rule for Budgeting
            Bliss
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            The 50/30/20 rule offers a straightforward approach to budgeting
            that prioritizes your needs
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-six")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              25 September 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Finance
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            Cracking the Dilemma: Why Personal Finance Matters in India
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            Understanding personal finance is your ticket to achieving dreams
            and securing a stable future
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-one")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              22 August 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Investment
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            The Magic of Compounding: Your Silent Wealth Builder
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            Embark on a journey of financial prosperity that spans generations
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-two")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              24 August 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Stock Market
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            Diversify Like Ambani: Build a Stronger Financial Future
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            Create a personalized diversification plan that aligns with your
            financial goals
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col space-y-4 p-3 rounded-lg transition duration-300 ease-in-out hover:bg-gray-300 hover:shadow-lg hover:cursor-pointer"
          onClick={() => navigate("/blog-three")}
        >
          <div className="flex gap-3">
            <div className="text-[#4B5563] font-normal text-[14px] leading-6 flex items-center justify-center">
              26 August 2024
            </div>
            <div className="bg-[#F9FAFB] border rounded-[20px] px-[10px] py-[2px] text-[#374151] font-medium text-[14px] leading-6">
              Finance
            </div>
          </div>
          <div className="text-[#111827] font-semibold text-[16px] md:text-[18px] leading-6 md:leading-7 h-12">
            Understanding the Time Value of Money
          </div>
          <div className="text-[#4B5563] font-normal text-[13px] md:text-[16px] leading-6 md:leading-7 h-20">
            Make smarter financial choices and increase your wealth over time
          </div>
          <div className="flex gap-4">
            <img src={KhyatiAvatar} alt="" className="h-10 w-10 object-cover" />
            <div className="flex flex-col">
              <div className="text-[#111827] font-semibold text-[16px] leading-6">
                Khyati Gandhi
              </div>
              <div className="text-[#4B5563] font-normal text-[14px] leading-6">
                Product Designer
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <style>
        {`
                    .slick-prev, .slick-next {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .slick-prev {
                        left: -60px;
                    }
                    .slick-next {
                        right: -60px;
                    }

                    @media (max-width: 1024px) {
                        .slick-prev {
                            left: -40px;
                        }
                        .slick-next {
                            right: -40px;
                        }
                    }

                    @media (max-width: 600px) {
                        .slick-prev {
                            left: -20px;
                        }
                        .slick-next {
                            right: -20px;
                        }
                    }

                    .slick-prev:before, .slick-next:before {
                        font-size: 20px;
                        color: #374151;
                    }
                `}
      </style>
    </div>
  );
};

export default BlogPost;
