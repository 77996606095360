import Modal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Blog from "./pages/Blog";
import BlogPageFive from "./pages/BlogPages/BlogPageFive";
import BlogPageFour from "./pages/BlogPages/BlogPageFour";
import BlogPageOne from "./pages/BlogPages/BlogPageOne";
import BlogPageSix from "./pages/BlogPages/BlogPageSix";
import BlogPageThree from "./pages/BlogPages/BlogPageThree";
import BlogPageTwo from "./pages/BlogPages/BlogPageTwo";
import Company from "./pages/Company";
import ContactUs from "./pages/ContactUs";
import Dashboard from "./pages/Dashboard";
import DataPage from "./pages/DataPage";
import EmailLogin from "./pages/EmailLogin";
import HomePage from "./pages/HomePage";
import MobileLogin from "./pages/MobileLogin";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Product from "./pages/Product";
import ProductsAndServices from "./pages/ProductsAndServices";
import Solutions from "./pages/Solutions";

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import NotFound from "./pages/NotFound";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgo_txIzBRlWHEsBrF7SrDpxOOuL2_sh8",
  authDomain: "prod-frontend-app-borp.firebaseapp.com",
  projectId: "prod-frontend-app-borp",
  storageBucket: "prod-frontend-app-borp.appspot.com",
  messagingSenderId: "535616507972",
  appId: "1:535616507972:web:07c1a8ea510765aeb12c11",
  measurementId: "G-VS615XCQ7K",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Modal.setAppElement("#root");

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/company" element={<Company />}></Route>
          <Route path="/product" element={<Product />}></Route>
          <Route path="/solutions" element={<Solutions />}></Route>
          <Route path="/blogs" element={<Blog />}></Route>
          <Route path="/blog-one" element={<BlogPageOne />}></Route>
          <Route path="/blog-two" element={<BlogPageTwo />}></Route>
          <Route path="/blog-three" element={<BlogPageThree />}></Route>
          <Route path="/blog-four" element={<BlogPageFour />}></Route>
          <Route path="/blog-five" element={<BlogPageFive />}></Route>
          <Route path="/blog-six" element={<BlogPageSix />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="products-services"
            element={<ProductsAndServices />}
          ></Route>
          <Route path="/mobile-login" element={<MobileLogin />}></Route>
          <Route path="/email-login" element={<EmailLogin />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/data" element={<DataPage />}></Route>

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
