import React from "react";
import Mobile from "../assets/images/Mobile.png";
import CustomDashboard from "../assets/images/CustomDashboard.png";
import FinanceManagement from "../assets/images/FinanceManagement.png";
import Insights from "../assets/images/insights.png";
import Cards from "../assets/images/Cards.svg";
import User from "../assets/images/User.png";
import NwtGpt from "../assets/images/Icons/NwtGpt.png";
import BlueDot from "../components/BlueDot";
import Tracking from "../assets/images/temp/Tracking.png";
import Security from "../assets/images/temp/Security.png";
import GridLeft from "../assets/images/GridLeft.png";
import GridRight from "../assets/images/GridRight.png";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const Product = () => {
  return (
    <>
      <Helmet>
        <title>
          Networth Tracker Products - Empower your financial growth with our
          tailored solutions
        </title>
        <meta
          name="description"
          content="Gain a clear picture of your finances, make informed decisions, and achieve your financial goals with financial clarity and confidence."
        />
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <link rel="canonical" href="/product" />
      </Helmet>
      <div className="tracking-wide">
        <NavBar />
        <div className="">
          <div className="relative px-5 lg:px-[120px] pb-6 md:py-12 xl:py-20 flex flex-col gap-8 bg-[#111827]">
            <div className="absolute lg:left-0 top-0 lg:top-4 opacity-15">
              <img src={GridLeft} alt="" />
            </div>
            <div className="absolute bottom-0 lg:right-0 lg:top-4 opacity-15">
              <img src={GridRight} alt="" />
            </div>
            <div className="flex flex-col items-center justify-center gap-4">
              <div className="text-blue-secondary text-center font-semibold">
                Product Features
              </div>
              <div className="text-[#FFFFFF] text-center font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8] w-full lg:w-[60%]">
                Empower your financial growth with our tailored solutions
              </div>
              <div className="text-[#B0B0B0] text-center font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 w-full lg:w-[60%]">
                Gain a clear picture of your finances, make informed decisions,
                and achieve your financial goals with financial clarity and
                confidence.
              </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-6">
              <div className="border rounded-[10px]  w-fit xl:w-[70%] relative flex flex-col gap-10 bg-[#1A2232]">
                <div className="flex flex-col gap-2 p-4 lg:p-8">
                  <div className="text-[#FFFFFF] font-bold text-[20px] leading-[30px]">
                    Customizable Dashboards
                  </div>
                  <div className="text-[#B0B0B0] font-normal text-[16px] leading-6 w-full lg:w-[85%]">
                    Create a financial dashboard that consolidates all of your
                    finances in one place. Customize this unique dashboard to
                    get instant insights with layouts tailored exclusively to
                    your needs.
                  </div>
                </div>
                <div className="flex flex-col justify-center xl:justify-between xl:flex-row gap-6 lg:gap-6">
                  <div className="mx-auto">
                    <img src={Mobile} alt="" className="max-h-72" />
                  </div>
                  <div className="mx-auto xl:mx-0">
                    <img src={CustomDashboard} alt="" className="xl:h-72" />
                  </div>
                </div>
              </div>
              <div className="border rounded-[10px] w-fit xl:w-[30%] flex flex-col p-4 lg:p-6 gap-2 bg-[#1A2232]">
                <div className="text-[#FFFFFF] font-semibold text-[20px] leading-[30px]">
                  Integrated Financial Management
                </div>
                <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                  Streamline your finances with our all-in-one suite, enabling
                  you to track expenses, manage budgets, and oversee investments
                  for optimized financial growth.
                </div>
                <div className="flex items-center justify-center">
                  <div className="py-12 flex items-center justify-center">
                    <img src={FinanceManagement} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:justify-between gap-6">
              {/* <div className='md:flex items-center justify-center'> */}
              <div className="border rounded-[10px] p-4 xl:p-8 bg-[#1A2232] flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-[#FFFFFF] font-semibold text-[20px] leading-[30px]">
                    Data-Driven Financial Insight
                  </div>
                  <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                    Uncover hidden financial opportunities with our data-driven
                    insights.
                  </div>
                </div>
                <div className="overflow-hidden flex items-center justify-center">
                  <img src={Insights} alt="" />
                </div>
              </div>
              <div className="border rounded-[10px] p-4 xl:p-8 bg-[#1A2232] relative flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-[#FFFFFF] font-semibold text-[20px] leading-[30px]">
                    Interactive Financial Education
                  </div>
                  <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                    Learn personal finance your way with interactive lessons
                    designed to empower you.
                  </div>
                </div>
                <div className="overflow-hidden flex items-center justify-center">
                  <img src={Cards} alt="" className="xl:absolute xl:bottom-0" />
                </div>
              </div>
              {/* </div> */}
              {/* <div className='md:flex items-center justify-center'> */}
              <div className="border rounded-[10px] p-4 lg:p-8 bg-[#1A2232] flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-2">
                  <div className="text-[#FFFFFF] font-semibold text-[20px] leading-[30px]">
                    Discover Your Financial Insights with our nwtGPT
                  </div>
                  <div className="text-[#B0B0B0] font-normal text-[16px] leading-6">
                    Experience our LLM-powered chat interactions that provide
                    unparalleled insights into your spending patterns, overall
                    financial health, budgeting strategies, and income
                    management through personalized conversations.
                  </div>
                </div>
                <div className="relative flex flex-col gap-4">
                  <div className="xl:absolute flex gap-2 xl:-right-4 border rounded-[8.97px] bg-[#FFFFFF] px-3 py-2">
                    <div className="flex items-center justify-center">
                      <img src={User} alt="" />
                    </div>
                    <div className="text-[#737373] font-medium text-[12px] leading-[18.6px]">
                      Hey nwtGPT, How do I manage my investment portfolio?
                    </div>
                  </div>
                  <div className="xl:absolute xl:top-[72px] xl:-left-4 flex gap-2 border rounded-[8.97px] bg-[#FFFFFF] px-3 py-2">
                    <div className="flex items-center justify-center">
                      <img src={NwtGpt} alt="" />
                    </div>
                    <div className="text-[#737373] font-medium text-[12px] leading-[18.6px]">
                      Diversify your assets, regularly review performance, and
                      adjust based on goals and market conditions.
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="px-5 xl:px-[120px] py-5 flex flex-col gap-8">
            <div className="flex flex-col py-8 md:py-12 xl:py-24 gap-12 md:gap-24">
              <div className="flex flex-col xl:flex-row-reverse gap-6 md:gap-14 xl:gap-20">
                <div className="w-full xl:w-[50%] space-y-4">
                  <div className="flex items-center justify-center border rounded-[10px] px-2 py-1 w-fit gap-2">
                    <BlueDot />
                    <div className="text-[#0A0A0A] font-medium text-[12px] leading-[18.6px]">
                      Tracking
                    </div>
                  </div>
                  <div className="text-[#0A0A0A] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px]">
                    All your finances, one platform
                  </div>
                  <div>
                    <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7">
                      Track your holdings, balances and transactions. Analyse
                      your entire financial life. From investments to everyday
                      expenses, we've got you covered.
                    </div>
                    <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7">
                      <ul className="list-disc ml-5">
                        <li>Stocks, Mutual Funds, ETFs, SIPs, PMS</li>
                        <li>Bank Accounts and Fixed Deposits</li>
                        <li>Credit Card and Loans</li>
                        <li>Life and Term Insurance</li>
                        <li>NPS, EPF and PPF</li>
                        <li>AIF, Bonds, Gold & more</li>
                        <li>Real Estate</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-full xl:w-[50%] flex items-center justify-center">
                  <div className="h-fit w-fit md:w-full lg:h-[450px] lg:w-[560px] flex items-center justify-center">
                    <img src={Tracking} alt="" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col xl:flex-row gap-6 md:gap-14 xl:gap-20">
                <div className="w-full xl:w-[50%] space-y-4 flex flex-col justify-center">
                  <div className="flex items-center justify-center border rounded-[10px] px-2 py-1 w-fit gap-2">
                    <BlueDot />
                    <div className="text-[#0A0A0A] font-medium text-[12px] leading-[18.6px]">
                      Secured Data
                    </div>
                  </div>
                  <div className="text-[#0A0A0A] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.8px]">
                    Security of your financial data is our top priority
                  </div>
                  <div className="text-[#737373] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7">
                    Safeguard your financial information with advanced
                    encryption and strict security protocols. We ensure your
                    data remains private and secure, used solely for your
                    benefit and never shared. With regular audits and 100%
                    encryption, we comply with SEBI, RBI and DPDPA regulations.
                    Enjoy innovative services while maintaining control over
                    your data and managing your consent, all with a focus on
                    your privacy and security.
                  </div>
                </div>
                <div className="w-full xl:w-[50%]">
                  <div className="flex items-center justify-center">
                    <div className="h-fit w-fit lg:h-[450px] lg:w-[527px] flex items-center justify-center">
                      <img src={Security} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Product;
