import React from "react";

const OurPricayPolicy = () => {
  return (
    <div className="px-5 lg:px-[120px]">
      <div className="flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4">
        <div className="text-blue-primary text-center font-semibold">
          Last updated September 1, 2024
        </div>
        <div className="text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center">
          Privacy Policy
        </div>
        <div className="text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center">
          Our Privacy Policy outlines how we collect, use, and protect your
          information to ensure a secure and transparent online experience.
        </div>
      </div>
    </div>
  );
};

export default OurPricayPolicy;
