import React from "react";
import Modal from "react-modal";

const ShowOptions = (props) => {
  function closeModal() {
    props.setShowOptions(false);
    document.body.classList.remove("modal-open");
  }

  function afterOpenModal() {
    document.body.classList.add("modal-open");
  }

  function handleModalStates() {
    props.setShowModal(true);
    closeModal();
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: 0,
      border: 0,
      backgroundColor: "#F2F3F9",
      borderRadius: "28px 28px 28px 28px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.60)",
    },
  };
  return (
    <div>
      <Modal
        isOpen={props.showOptions}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex items-center justify-center h-[180px] w-[300px]">
          <button
            className="bg-red-900 h-fit w-[120px] rounded-[70px] px-1 py-2 m-2 text-white"
            onClick={() => {}}
          >
            Add banks
          </button>
          <button
            className="bg-red-900 h-fit w-[120px] rounded-[70px] px-1 py-2 m-2 text-white"
            onClick={handleModalStates}
          >
            Manual upload
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ShowOptions;