import React from "react";
import left from "../assets/images/left.png";
import right from "../assets/images/right.png";

const Header = () => {
  return (
    <div className="bg-blue-primary w-full h-[40px] text-[#FFFFFF] relative">
      <div className="absolute left-0 top-0 opacity-30 lg:opacity-100">
        <img src={left} alt="" />
      </div>
      <div className="flex items-center justify-center h-[100%] text-[12px] md:text-[14px]">
        Experience the Future with Networth Tracker FinAI Today!
      </div>
      <div className="absolute right-0 top-0 opacity-30 lg:opacity-100">
        <img src={right} alt="" />
      </div>
    </div>
  );
};

export default Header;
