import React, { useState, useEffect } from "react";
import OtpPage from "./OtpPage";

const MobileLogin = () => {
  const [mobNum, setMobNum] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (mobNum.length === 10) {
      const blockUntil = localStorage.getItem(`block_${mobNum}`);
      if (blockUntil && Date.now() < blockUntil) {
        setIsBlocked(true);
      } else {
        localStorage.removeItem(`block_${mobNum}`);
        setIsBlocked(false);
      }
    }
  }, [mobNum]);

  const handleChange = (e) => {
    setMobNum(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && mobNum.length === 10 && !isBlocked) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /[^0-9]/g;
    if (regex.test(mobNum)) {
      alert("Invalid Phone Number");
      return;
    }

    if (isBlocked) {
      alert("Your number is blocked. Please try again later.");
      return;
    }

    // Call  API

    setShowOtp(true);
  };

  return (
    <div className="h-screen flex justify-center">
      {!showOtp ? (
        <div className="flex flex-column mt-[60px] items-center">
          <div className="text-[45px] font-700 leading-[52px] p-6 mt-[10px]">
            Welcome!
          </div>
          <div className="text-gray-500 p-2 w-[260px] text-left">
            Enter your phone number to get one time password
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={mobNum}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              maxlength={10}
              className="w-[260px] px-4 py-2 mb-4 border border-gray-750 rounded-[10px] focus:outline-none"
            />
          </div>
          {mobNum?.length === 10 &&
            <div> {
              !isBlocked ? (
                <div className="relative">
                  <div
                    onClick={handleSubmit}
                    className="w-[260px] py-2 border rounded-[10px] bg-gradient-to-r from-blue-500 to-violet-500 text-white cursor-pointer flex items-center group pl-20"
                  >
                    Get OTP
                    <div className="h-full flex items-center ml-4 group-hover:ml-8 transition-all duration-500">
                      <i className="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-red-500 mt-2">
                  Your number is blocked for 2 hours. Please try again later.
                </div>
              )
            }
            </div>
          }
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <OtpPage length={4} text="Mobile number" setShowOtp={setShowOtp} mobNum={mobNum} setMobNum={setMobNum} />
        </div>
      )}
    </div>
  );
};

export default MobileLogin;
